<template>
	<div class="container my-2">
		<div class="row">
			<div class="col-12 mx-auto">
				<div class="d-flex mb-2 align-items-center">
					<el-page-header @back="goBack" title="返回"> </el-page-header>
					<p class="savedTxt text-start">{{ savedTxt }}</p>
					<el-button
						v-if="postInfo.published"
						class="ms-auto"
						type="info"
						round
						plain
						@click="publishedChange()"
						>已發布</el-button
					>
					<el-button
						v-else
						class="ms-auto"
						type="success"
						round
						@click="publishedChange()"
						>發布</el-button
					>
				</div>

				<el-input
					size="large"
					class="mb-3"
					placeholder="標題"
					v-model="postInfo.title"
					@blur="savePost"
				></el-input>

				<div class="d-flex flex-wrap mb-3">
					<el-tag
						:key="tag"
						class="me-2"
						v-for="tag in postInfo.tags"
						closable
						:disable-transitions="false"
						@close="handleClose(tag)"
					>
						#{{ tag.label }}
					</el-tag>
					<el-autocomplete
						class="input-new-tag"
						v-if="inputVisible"
						v-model="inputValue"
						ref="saveTagInput"
						size="small"
						@keyup.enter="handleInputConfirm"
						@blur="handleInputConfirm"
						:fetch-suggestions="querySearch"
						placeholder="輸入標簽名稱"
						:trigger-on-focus="false"
					>
					</el-autocomplete>
					<el-button
						v-else
						class="button-new-tag"
						size="small"
						@click="showInput"
						>+ 新標籤</el-button
					>
				</div>
				<template v-if="postInfo.videoPath != ''">
					<div class="text-start">
						<el-button type="danger" plain size="small" @click="removeVideo()"
							>X 移除影片</el-button
						>
						<video
							:src="postInfo.videoPath"
							controls
							class="w-100 my-2"
						></video>
					</div>
				</template>

				<div v-else class="my-2 text-start">
					<el-upload
						:http-request="uploadFile"
						:before-upload="beforeUpload"
						:show-file-list="false"
					>
						<!-- :on-preview="previewFile"
      :before-remove="beforeFileRemove" -->
						<el-button color="#b9d6f3" type="success" @click="submitUpload"
							>添加影片檔(mp4,avi)</el-button
						>

						<div :v-slot="tip" class="el-upload__tip ms-2">請上傳影片檔案</div>
					</el-upload>
				</div>

				<!-- <video :src="videoSrc" controls width="600"></video> -->

				<ckeditor
					:editor="editor"
					v-model="postInfo.content"
					@ready="onReady"
					@blur="savePost"
					:config="editorConfig"
				></ckeditor>
			</div>
		</div>
	</div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";
import UploadAdapter from "../../../services/CKEditor/UploadAdapter";
// import { VideoPlayer } from "@videojs-player/vue";

export default {
	components: {
		ckeditor: CKEditor.component,
		// VideoPlayer
	},
	data() {
		return {
			editor: ClassicEditor,
			savedTxt: "",
			timerId: "",
			postInfo: {
				id: "0",
				title: "",
				content: "",
				published: true,
				tags: [],
				videoPath: "",
			},
			editorConfig: {
				extraPlugin: [this.uploader],
			},
			options: [],
			inputVisible: false,
			inputValue: "",
		};
	},
	methods: {
		removeVideo() {
			let vm = this;
			vm.postInfo.videoPath = "";
			vm.savePost();
		},
		uploadFile(param) {
			const vm = this;
			let formData = new FormData();
			formData.append("file", param.file);
			// console.log(param);
			vm.fetchAPI(
				"post",
				`${process.env.VUE_APP_URL_API}/files/file`,
				formData
			).then((res) => {
				vm.imgUrl = res.data;
				vm.postInfo.videoPath = res.data;
				vm.savePost();
			});
		},
		beforeUpload(file) {
			const isVideo =
				file.type === "video/mp4" ||
				file.type === "video/avi" ||
				file.type === "video/quicktime";
			if (!isVideo) {
				this.$message({
					type: "error",
					message: "只能上傳 MP4, AVI, MOV 類型的影片檔！",
				});
			}
			return isVideo;
		},
		showInput() {
			this.inputVisible = true;
		},
		querySearch(queryString, cb) {
			let vm = this;
			vm.fetchAPI(
				"get",
				`${process.env.VUE_APP_URL_API}/Post/GetSearchTags/${queryString}`,
				{},
				false
			)
				.then((res) => {
					// 過濾掉已經存在於 vm.postInfo.tags 中的元素
					const uniqueData = res.data.filter((item) =>
						vm.postInfo.tags.some((tag) => tag.vlaue === item.value)
					);
					console.log(res.data, uniqueData);
					// 將過濾後的資料傳遞給回調函數
					cb(uniqueData);
				})
				.catch((err) => {
					console.log(err);
				});
		},
		handleInputConfirm() {
			let vm = this;

			let inputValue = vm.inputValue;
			console.log(inputValue);
			if (inputValue != "") {
				vm.fetchAPI(
					"post",
					`${process.env.VUE_APP_URL_API}/Post/Tag/Add`,
					{
						PostId: vm.$route.params.id,
						TagName: inputValue,
					},
					false
				)
					.then((res) => {
						console.log(res.data);
						this.postInfo.tags.push(res.data);
					})
					.catch((err) => {
						console.log(err);
					});
			}
			this.inputVisible = false;
			this.inputValue = "";
		},
		handleClose(tag) {
			let vm = this;
			vm.fetchAPI(
				"delete",
				`${process.env.VUE_APP_URL_API}/Post/Tag/Delete/${tag.value}`,
				{},
				false
			)
				.then((res) => {
					console.log(res.data);
					this.postInfo.tags.splice(this.postInfo.tags.indexOf(tag), 1);
				})
				.catch((err) => {
					console.log(err);
				});
		},

		remoteMethod(query) {
			if (query !== "") {
				this.loading = true;
				let vm = this;
				vm.fetchAPI(
					"get",
					`${process.env.VUE_APP_URL_API}/Post/GetSearchTags/${query}`,
					{},
					false
				)
					.then((res) => {
						console.log(res.data);

						res.data.forEach((e) => {
							if (!this.options.some((option) => option.label === e.label)) {
								// 如果不存在，則將元素添加到 options 陣列中
								this.options.push({ value: e.value, label: e.label });
							}
						});
						console.log(this.options);
						this.loading = false;
					})
					.catch((err) => {
						console.log(err);
					});
			}
		},
		loadData() {
			let vm = this;

			vm.fetchAPI(
				"get",
				`${process.env.VUE_APP_URL_API}/Post/GetPost/${this.$route.params.id}`
			)
				.then((res) => {
					console.log(res.data);

					vm.postInfo = res.data;
					vm.options = res.data.options;
					console.log(vm.postInfo);
				})
				.catch((err) => {
					vm.savedTxt = "讀取異常,請稍後再試";
					console.log(err);
				});
		},
		onReady(editor) {
			editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
				return new UploadAdapter(loader);
			};
		},
		publishedChange() {
			let vm = this;
			vm.postInfo.published = !vm.postInfo.published;
			if (vm.postInfo.published == true) {
				if (vm.postInfo.title == "") {
					vm.notify("error", `發布前，請輸入標題`);
					vm.postInfo.published = !vm.postInfo.published;
					return;
				}
				if (vm.postInfo.content == "") {
					vm.notify("error", `發布前，請輸入內容`);
					vm.postInfo.published = !vm.postInfo.published;
					return;
				}
			}
			let result = vm.savePost();
			if (result) {
				vm.notify("success", vm.postInfo.published ? `已發布` : `已下架`);
			}
		},
		savePost() {
			let vm = this;
			console.log(vm.postInfo.tags);
			vm.fetchAPI(
				"Post",
				`${process.env.VUE_APP_URL_API}/Post/PostSave`,
				vm.postInfo,
				false
			)
				.then((res) => {
					clearTimeout(vm.timerId);
					vm.savedTxt = "儲存中...";
					console.log(res);
					vm.savedTxt = "已儲存";
					vm.timerId = setTimeout(() => {
						vm.savedTxt = "";
					}, 2000);

					vm.options = [];
					vm.postInfo.tags.forEach((e) => {
						this.options.push({ value: e, label: e });
					});
				})
				.catch((err) => {
					vm.savedTxt = "儲存失敗請稍後再試";
					console.log(err);
				});
			return true;
		},
		goBack() {
			this.$router.go(-1);
		},
	},
	mounted() {
		let vm = this;
		vm.loadData();
	},
};
</script>

<style lang="scss" scoped>
.savedTxt {
	font-size: xx-small;
}
.el-card {
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); /* 自定义阴影效果 */
}
.chargemeAI-title {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.input-new-tag {
	width: 90px;
	margin-left: 10px;
	vertical-align: bottom;
}
</style>
